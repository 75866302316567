import axios from "axios";
import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const login = async (inputs) => {
    const res = await axios.post("https://kidpedia.net/api/auth/login", inputs);
    setCurrentUser(res.data);
    console.log(res.data)
  };
  const logout = async (inputs) => {
    await axios.post("https://kidpedia.net/api/auth/logout");
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  //Reflesh token bölümü
  // const refreshToken = async () => {
  //   try {
  //     const res = await axios.post("/auth/refresh", {
  //       token: currentUser.refreshToken,
  //     });
  //     setCurrentUser({
  //       ...currentUser,
  //       accessToken: res.data.accessToken,
  //       refreshToken: res.data.refreshToken,
  //     });
  //     console.log(res);
  //     return res.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  
  if (currentUser !== null){
    let currentDate = new Date();

    const decodedToken = jwt_decode(currentUser.accessToken);
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
   logout();
  }}else{
  console.log("veri kontrol çıkış")
  }


  // const axiosJWT = axios.create();

  // axiosJWT.interceptors.request.use(
  //   async (config) => {
  //     let currentDate = new Date();
  //     const decodedToken = jwt_decode(currentUser.accessToken);
  //     if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //       const data = await refreshToken();
  //       config.headers["authorization"] = "Bearer " + data.accessToken;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
