import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Pagination, Button } from "react-bootstrap";
import axios from "axios";
import DOMPurify from "dompurify";

const PAGE_SIZE = 5;

function Randevu() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [udata, usetData] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [showPastDates, setShowPastDates] = useState(false);

  useEffect(() => {
    axios
      .get(`https://kidpedia.net/api/freebook/getuser`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://kidpedia.net/api/store/getusersiparispersonal`)
      .then((res) => {
        usetData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(udata);

  const getSerialNumber = (index, currentPage) => {
    const serialNumber = (currentPage - 1) * PAGE_SIZE + index + 1;
    return serialNumber;
  };

  const formatDateString = (date) => {
    if (!date) return "";
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("tr-TR", options);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filteredPosts = data.filter((post) => {
    const postDate = new Date(post.date);
    return showPastDates || postDate >= today || post.date === null;
  });

  const filteredPosts2 = udata.filter((post) => {
    const postDate = new Date(post.date);
    return showPastDates || postDate >= today || post.date === null;
  });

  const totalPages = Math.ceil(filteredPosts.length / PAGE_SIZE);
  const visiblePosts = filteredPosts.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const totalPages2 = Math.ceil(filteredPosts2.length / PAGE_SIZE);
  const visiblePosts2 = filteredPosts2.slice(
    (currentPage2 - 1) * PAGE_SIZE,
    currentPage2 * PAGE_SIZE
  );

  return (
    <>
      <Container>
        {visiblePosts2.length > 0 && (
          <Row>
            <h4>Ücretli Görüşme Linki</h4>
            {visiblePosts2.map((post, index) => (
              <Container key={index}>
                <Card className="mb-4">
                  <Row className="no-gutters">
                    <Col>
                      <Card.Body>
                        <Card.Title>
                          <div>
                            <strong>Randevu Bilgileriniz</strong>
                          </div>
                          {getSerialNumber(index, currentPage2)}.{" "}
                          {formatDateString(post.date)}{" "}
                          {post.time && post.time.slice(0, 5)} {post.urun_adi}
                        </Card.Title>
                        <Card.Text>
                          <Col
                            sm={10}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                post.url !== null
                                  ? post.url
                                  : "Görüşme linki ve tarihi henüz eklenmemiştir. Kısa süre içinde veri görüşme linki eklenecektir."
                              ),
                            }}
                          ></Col>
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Container>
            ))}
            <div>
              <Pagination>
                {Array.from({ length: totalPages2 }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={currentPage2 === index + 1}
                    onClick={() => setCurrentPage2(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </Row>
        )}

        {visiblePosts.length > 0 && (
          <Row>
            <h4>Ücretsiz Görüşme Linki</h4>
            {visiblePosts.map((post, index) => (
              <Container key={index}>
                <Card className="mb-4">
                  <Row className="no-gutters">
                    <Col>
                      <Card.Body>
                        <Card.Title>
                          <div>
                            <strong>Randevu Bilgileriniz</strong>
                          </div>
                          {getSerialNumber(index, currentPage)}.{" "}
                          {formatDateString(post.date)}{" "}
                          {post.time && post.time.slice(0, 5)}
                        </Card.Title>
                        <Card.Text>
                          <Col
                            sm={10}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                post.url !== null
                                  ? post.url
                                  : "Görüşme linki henüz eklenmemiştir. Kısa süre içinde veri görüşme linki eklenecektir."
                              ),
                            }}
                          ></Col>
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Container>
            ))}
            <div>
              <Pagination>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={currentPage === index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </Row>
        )}

        {visiblePosts.length === 0 && visiblePosts2.length === 0 && (
          <Row>
            <Container>
              <Card className="mb-4">
                <Row className="no-gutters">
                  <Col>
                    <Card.Body>
                      <Card.Title>Randevunuz Bulunmamaktadır</Card.Title>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Container>
          </Row>
        )}

        <Row>
          <Button variant="link" onClick={() => setShowPastDates(!showPastDates)}>
            {showPastDates ? "Geçmiş Tarihleri Gizle" : "Geçmiş Tarihleri Göster"}
          </Button>
        </Row>
      </Container>
    </>
  );
}

export default Randevu;