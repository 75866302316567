import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";
import { RiWhatsappLine } from "react-icons/ri";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";



const Contact = () => {
  const [eachEntry, setEachEntry] = useState("");

  const { name, email,telefon, message } = eachEntry;

  const handleOnChange = (event) => {
    setEachEntry({...eachEntry, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
axios.post(`https://kidpedia.net/api/posts/contact`, eachEntry)
      .then((response) => {
        toast.success("Mesajınız başarıyla gönderildi");
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors (e.g., show error message)
        console.error(error);
        toast.error("Bir hata oluştu");
      });
  };



  return (
    <>
    <Helmet>
      <title>İletişim</title>
    </Helmet>
    <ToastContainer />
      <Container>
        <h1>İletişim</h1>
        <br></br>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group>
                <Form.Label>İsim - Soyisim</Form.Label>
                <Form.Control onChange={handleOnChange} value={name} name="isim" />
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control onChange={handleOnChange} value={email} name="email" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Telefon</Form.Label>
                <Form.Control onChange={handleOnChange} value={telefon} name="telefon" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Mesaj</Form.Label>
                <Form.Control
                  onChange={handleOnChange}
                  value={message}
                  name="mesaj"
                />
              </Form.Group>
              <Button className="mt-3" onClick={handleSubmit} variant="success" type="submit”">
                Gönder
              </Button>
            </Form>
          </Col>
          <Col md={6}>
            <Card className="mt-4">
              <Card.Body>
                <Card.Title
                  className="d-flex align-items-center"
                  style={{ textAlign: "center" }}
                >
                  Bize Whatsapp ve Telefon Numarası ile ulaşabilirsiniz.
                </Card.Title>
                <Card.Text className="p-4">
                  {" "}
                  <RiWhatsappLine style={{ width: 50, height: 50 }} /> +90 282
                  606 06 96{" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
