import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // For extracting the token from URL
import axios from 'axios';

const ResetPassword = () => {
  const { token2 } = useParams(); // Extract token from URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  console.log(token2);
  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
      await axios.post('https://kidpedia.net/api/auth/reset-password', { token2, password });
  setMessage('Şifre Başarılı bir şekilde yenilendi.');
    } catch (error) {
  setMessage('Şifre değişmede hata oluştu.');
    }
  };

  return (
    <div>
      <h2>Şifreni Yenile</h2>
      <p>Yeni Şifreni Gir.</p>
      <input
        type="password"
        placeholder="New Password"
        value={password}
        onChange={handlePasswordChange}
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />
      <button onClick={handleResetPassword}>Şifremi Yenile</button>
      <p>{message}</p>
    </div>
  );
};

export default ResetPassword;
