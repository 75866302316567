import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdFactory } from "react-icons/md";
import { FaSchool } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap"; // Import React Bootstrap components

const KurumsalSideBar = ({ children }) => {
  const menuItem = [
    
    {
      path: "/kurumsal/sirket",
      name: "Şirketlere Yönelik Hizmetler",
      icon: (
        <MdFactory style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
    {
      path: "/kurumsal/egitim",
      name: "Eğitim Kurumlarına Yönelik Hizmetler",
      icon: (
        <FaSchool style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
  ];

  const location = useLocation();

  return (
    <Container fluid>
      <Row>
        {menuItem.map((item, index) => (
          <Col
            key={index}
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "100%" }} // Set the height of the column to 100%
          >
            <NavLink
              end
              to={item.path}
              key={index}
              className="link"
              activeClassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default KurumsalSideBar;
