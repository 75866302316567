import React  from 'react';
import { RiDashboardLine, RiVideoLine, RiCalendar2Line, RiTaskLine } from "react-icons/ri";

import { NavLink } from 'react-router-dom';


const Sidebar = ({children}) => {
    
    const menuItem=[
        {
            path:"/profile",
            name:"Kontrol Paneli",
            icon:<RiDashboardLine  style={{ height: "2rem", width: "2rem", }}/>
        },
        {
            path:"/profile/video",
            name:"Video Yükle",
            icon:<RiVideoLine style={{ height: "2rem", width: "2rem", }} />
        },
        {
            path:"/profile/randevu",
            name:"Randevular",
            icon:<RiCalendar2Line style={{ height: "2rem", width: "2rem", }}/>
        },
        {
        path:"/profile/egitimonerileri",
            name:"Eğitim Önerileri",
            icon:<RiTaskLine style={{ height: "2rem", width: "2rem", }}/>
        },
        
    ]
    return (
        <div className="container">
                              {
                   menuItem.map((item, index)=>(
                       
                    //    Buradaki end ile tüm linklerin active olmasının önüne geçiyoruz.
                       <NavLink end to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div  className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
        </div>
    );
};

export default Sidebar;