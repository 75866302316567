import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { RiEdit2Fill, RiDeleteBin7Fill, RiArrowLeftLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import jwt from "jwt-decode"; // Import jsonwebtoken library for JWT decoding

const Single = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const catId = location.pathname.split("/")[2];
  const postId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/posts/${postId}`);
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  // Get the JWT token from localStorage
  const tokenString = localStorage.getItem("user");
  const token = tokenString ? JSON.parse(tokenString) : null;  // Decode the JWT token

  // Check if the decoded token exists and the user role is "admin"
  const isAdmin = token && token.role === "admin";
  ;

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/posts/${postId}`);
      setTimeout(() => {
        navigate("/admin/adminposts");
      }, 1000); // Delay the redirection by 1 second (adjust the time as needed)
    } catch (err) {
      console.log(err);
      console.log(postId);
    }
  };
  
  return (
    <div>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <>
              <Button onClick={() => navigate(-1)}>
                <RiArrowLeftLine /> Geri Dön
              </Button>
              <img
                src={`https://kidpedia.net/upload/${post?.img}`}
                className="w-50 img-fluid rounded mx-auto d-block"
                alt=""
              />
            </>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <h1 className="text-center">{post.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* Render the "Edit" and "Delete" icons only for admin users */}
            {isAdmin && (
              <>
                <RiEdit2Fill
                  onClick={() => navigate(`/update/${post.id}`)}
                  style={{
                    height: "2rem",
                    width: "2rem",
                    color: "black",
                  }}
                />
                <a href={`/update/${post.id}`}>
                  {" "}
                  <RiDeleteBin7Fill
                    style={{ height: "2rem", width: "2rem", color: "black" }}
                    onClick={() => handleDelete(post.id)}
                  />
                </a>{" "}
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="text-justify d-grid gap-3 p-1">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.desc),
              }}
            ></div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Card
              className="card align-items-center "
              style={{ width: "auto", color: "black" }}
            >
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-10">
                  Yazar
                </Card.Title>
                <Card.Title className="card align-items-center fw-bolder fs-7">
                  {post.yazaradi}{" "}
                </Card.Title>
                <Card.Title className="card align-items-center fw-bolder fs-7">
                  {post.yazarunvan}
                </Card.Title>
                <Card.Title className="card align-items-center fw-bolder fs-7">
                  {post.date
                    ? new Date(post.date).toLocaleDateString("tr-TR")
                    : ""}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Single;
