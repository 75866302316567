import React, { createContext,useCallback, useState, useEffect } from "react";
import axios from "axios";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [product, setProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateTotalPrice = useCallback((data) => {
    let totalPrice = 0;
    data.forEach((product) => {
      totalPrice += product.fiyat;
    });
    setTotalPrice(totalPrice);
  }, [setTotalPrice]);
  const fetchCartData = useCallback(async () => {
    try {
      const res = await axios.get(`https://kidpedia.net/api/store/getcart`);
      setProduct(res.data);
      calculateTotalPrice(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [setProduct, calculateTotalPrice]);
  useEffect(() => {
    fetchCartData();
  }, [fetchCartData]);

  return (
    <CartContext.Provider
      value={{
        product,
        totalPrice,
        calculateTotalPrice,
        fetchCartData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
