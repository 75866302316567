import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import axios from "axios";
import { Helmet } from "react-helmet";

const Dergi = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/posts/dergi/dergi`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const indexOfLastPost = currentPage === 1 ? 8 : currentPage * postsPerPage + 3;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts =
    currentPage === 1
      ? posts.slice(3, indexOfLastPost)
      : posts.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Helmet>Kidpedia Dergi</Helmet>
      <Container>
        <Row>
          <Col md={9}>
            {posts.length > 0 ? (
              <>
                <Link
                  to={`/posts/${posts[0]?.cat}/${posts[0]?.id}/${posts[0]?.title}`}
                  className="text-decoration-none text-dark"
                >
                  <Image
                    src={`https://kidpedia.net/upload/${posts[0]?.img}`}
                    fluid
                    style={{
                      width: "900px",
                      height: "450px",
                    }}
                  />
                  <h3 className="mt-3">{posts[0]?.title}</h3>
                </Link>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      posts[0]?.desc.length > 100
                        ? posts[0]?.desc.substring(0, 100) + "..."
                        : posts[0]?.desc
                    ),
                  }}
                ></p>{" "}
                <p className="fw-bolder fs-7 opacity-75">{posts[0]?.month}</p>
              </>
            ) : (
              <p>Veri bulunamadı</p>
            )}
          </Col>
          <Col md={3}>
            {Array.isArray(posts) && posts.length > 1 ? (
              posts.slice(1, 3).map((item) => (
                <div key={item.id}>
                  <Link
                    to={`/posts/${item.cat}/${item.id}/${item.title}`}
                    className="text-decoration-none text-dark "
                  >
                    <Image
                      className="p-1"
                      src={`https://kidpedia.net/upload/${item?.img}`}
                      fluid
                      style={{
                        width: "300px",
                        height: "150px",
                      }}
                    />
                    <h5 className="mt-2">{item.title}</h5>
                  </Link>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        item.desc.length > 100
                          ? item.desc.substring(0, 100) + "..."
                          : item.desc
                      ),
                    }}
                  ></p>{" "}
                  <p className="fw-bolder fs-7 opacity-75">{item.month}</p>
                </div>
              ))
            ) : (
              <p>Veri bulunamadı</p>
            )}
          </Col>
        </Row>
        {Array.isArray(currentPosts) ? (
          currentPosts.map((post) => (
            <Row key={post.id}>
              <Card className="mb-4">
                <Row className="no-gutters">
                  <Col md={3} lg={3} className="p-1">
                    <Card.Img
                      className="p-2"
                      variant="top"
                      src={`https://kidpedia.net/upload/${post?.img}`}
                      style={{
                        width: "300px",
                        height: "150px",
                      }}
                    />
                    <Card.Title className="text-center fw-bolder fs-5 opacity-75">
                      {post.month}
                    </Card.Title>
                  </Col>
                  <Col>
                    <Card.Body>
                      <Card.Title>{post.title}</Card.Title>
                      <Card.Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              post.desc.length > 103
                                ? post.desc.substring(0, 103) + "..."
                                : post.desc
                            ),
                          }}
                        ></div>
                      </Card.Text>
                      <Button
                        variant="primary"
                        href={`/post/${post.cat}/${post.id}/${post.title}`}
                      >
                        Devamını Oku
                      </Button>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Row>
          ))
        ) : (
          <p>Veri bulunamadı</p>
        )}
      </Container>

      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              className={`page-item ${currentPage === number ? "active" : ""}`}
              key={number}
            >
              <button className="page-link" onClick={() => paginate(number)}>
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Dergi;
