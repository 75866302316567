import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArticleSidebar from "../components/ArticleSidebar";
import ArticlesTitles from "../components/ArticlesTitles";
import Helmet from "react-helmet";
const Articles = () => {
  return (
    <><Helmet>
    <title>Makaleler</title>
    <meta name="description" content="Otizm" />{" "}
      <meta name="description" content="Öğrenme Güçlüğü" />
      <meta name="description" content="Gelişim Geriliği" />
      <meta name="description" content="Disleksi" />
      <meta name="description" content="Diskalkuli" />
      <meta name="description" content="Dil ve Konuşma Güçlüğü" />
      <meta name="description" content="Zihin Yetersizliği" />
  </Helmet>
      <Container>
        <Row>
          <Col md={3} className="border-end">
            {" "}
            <ArticleSidebar />
          </Col>
          <Col md={9}>
            {" "}
            <ArticlesTitles />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Articles;
