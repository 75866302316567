import React,{useContext} from "react";
import { Col, Container,Row } from 'react-bootstrap'
import { Outlet,Navigate } from 'react-router-dom'
import Sidebar from "../components/Sidebar"
import { AuthContext } from "../context/authContext";




const Profile = () => {
  const { currentUser } = useContext(AuthContext);





  return (

<>
{currentUser ? (
  <Container>
<Row>
<Col md={3} className="border-end"> <Sidebar/>
     </Col>
<Col md={9}> <Outlet /></Col>

</Row>
</Container>

): (<Navigate to="/login" />) }

</>

    )
}

export default Profile