import React from "react";
import Uzman from "../components/Uzmanlar";
import { Helmet } from "react-helmet";
const Uzmanlar = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Uzmanlar</title>
      </Helmet>
      <Uzman />
    </>
  );
};

export default Uzmanlar;
