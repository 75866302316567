import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import AdminHeader from "../components/Admin/AdminHeader";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,

} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";

const PAGE_SIZE = 5;

const AdminUcretliGorusme = () => {
  const { currentUser } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const [showOldPosts, setShowOldPosts] = useState(false);

  const navigate = useNavigate();

  // Veri Çekme
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/store/getusersiparis`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const refreshPage = () => {
    navigate(0);
  };

  // Veri Yazma
  const handleSave = async (postId) => {
    try {
      await axios.put(`https://kidpedia.net/api/store/getupdatesiparis/${postId}`, {
        url: value,
        date: startDate,
        time: time,
      });

      refreshPage();
    } catch (err) {
      console.log(err);
      console.log(postId);
    }
  };

  // Sort the posts in descending order based on the date
  const sortedPosts = posts.sort((a, b) => b.id - a.id);
  const filteredPosts = showOldPosts
    ? sortedPosts
    : sortedPosts.filter((post) => {
        const postDate = post.date ? new Date(post.date) : null;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return !postDate || postDate >= today;
      });

  // Admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
      return (
        <>
          <AdminHeader />
          <Container>
            <Row>
              {filteredPosts.map((post) => (
                <Container key={post.siparis_id}>
                  <Card className="mb-4">
                    <Row className="no-gutters">
                      <Col>
                        <Card.Body>
                          <Card.Title>
                            {post.siparis_id} Sipariş No: {post.siparis_no} Başlık: {post.urun_adi} Kişi:{" "}
                            {post.firstname} {post.lastname}
                          </Card.Title>
                          <Card.Text>
                            <Row>
                              <ReactQuill
                                theme="snow"
                                defaultValue={post.url}
                                onChange={setValue}
                                className="h-75"
                              />
                            </Row>
                            <Row className="p-3">
                              <Col>
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </Col>
                              <Col>
                                <TimePicker
                            value={time || post.time || null} // Use selectedPostTime if available, then post.time, or null
                            onChange={(newTime) => setTime(newTime)}                                  format="HH:mm"
                                  clearIcon={null}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Card.Title>
                                {post.date && post.time ? (
                                  <>
                                    Veri Kontrol Tarih:{" "}
                                    {post.date &&
                                      new Date(post.date).toLocaleDateString("tr-TR", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })}{" "}
                                    Saat: {post.time}
                                  </>
                                ) : (
                                  <span style={{ color: "red", fontWeight: "bold" }}>
                                    Giriş yapılmamış
                                  </span>
                                )}
                              </Card.Title>
                            </Row>
                          </Card.Text>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleSave(post.siparis_id)}
                          >
                            Kaydet
                          </Button>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Container>
              ))}
            </Row>
            <Row>
              <Button variant="danger" onClick={() => setShowOldPosts(!showOldPosts)}>
                {showOldPosts ? "Eski verileri gizle" : "Eski verileri görmek için tıklayın"}
              </Button>
            </Row>
          </Container>
        </>
      );
    case 503422:
      return <div>Moderator</div>;
    case 503439:
      return <div>BackendUser</div>;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default AdminUcretliGorusme;
