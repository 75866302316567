import { useEffect, useState, useContext } from "react";
import { AppointmentPicker } from "react-appointment-picker";
import axios from "axios";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Button, Card, CardImg } from "react-bootstrap";
import { getDay } from "date-fns";
import { enGB, tr } from "date-fns/locale";
import { AuthContext } from "../context/authContext";
import { Outlet, Navigate } from "react-router-dom";
import { RiAlertLine } from "react-icons/ri";
import {Helmet} from "react-helmet";

function Calender() {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date(Date.now() + 180 * 60 * 1000));
  const [days, setDays] = useState([[]]);
  const [appointment, setAppointment] = useState("");
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [lastSelectedAppointmentDate, setLastSelectedAppointmentDate] =
    useState(null);
  const [lastSelectedAppointmentTime, setLastSelectedAppointmentTime] =
    useState(null);
  const [lastSelectedAppointmentNumber, setLastSelectedAppointmentNumber] =
    useState(null);

  const trimmedDate = date.toISOString().slice(0, 10);

  async function checkReservation(date, time) {
    const response = await axios.get(`https://kidpedia.net/api/freebook/check`, {
      params: { date, time },
    });
    return response.data.isReserved;
  }

  async function getAppointmentDays(date) {
    const isReserved10 = checkReservation(date.toISOString(), "15:00:00");
    const isReserved11 = checkReservation(date.toISOString(), "16:00:00");
    const isReserved12 = checkReservation(date.toISOString(), "17:00:00");
    const isReserved13 = checkReservation(date.toISOString(), "18:00:00");
    const isReserved14 = checkReservation(date.toISOString(), "19:00:00");

    const [
      isReserved10Value,
      isReserved11Value,
      isReserved12Value,
      isReserved13Value,
      isReserved14Value,
    ] = await Promise.all([
      isReserved10,
      isReserved11,
      isReserved12,
      isReserved13,
      isReserved14,
    ]);

    const days = [
      [
        {
          id: 1,
          number: 1,
          isReserved: isReserved10Value,
        },
        {
          id: 2,
          number: 2,
          isReserved: isReserved11Value,
        },
        {
          id: 3,
          number: 3,
          isReserved: isReserved12Value,
        },
        {
          id: 4,
          number: 4,
          isReserved: isReserved13Value,
        },
        {
          id: 5,
          number: 5,
          isReserved: isReserved14Value,
        },
      ],
    ];
    return days;
  }
  useEffect(() => {
    if (date != null) {
      const initialDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        15,
        0,
        0
      );

      getAppointmentDays(date).then((days) => {
        setAppointment(
          <AppointmentPicker
            addAppointmentCallback={addAppointmentCallbackContinuousCase}
            removeAppointmentCallback={removeAppointmentCallbackContinuousCase}
            initialDay={initialDate}
            days={days}
            maxReservableAppointments={1}
            visible
            selectedByDefault
            unitTime={3600000}
            loading={loading}
            continuous
            local={"tr-TR"}
          />
        );
      });
    }
  }, [date, loading]);

  async function addAppointmentCallbackContinuousCase({
    addedAppointment: { day, number, time, id },
    addCb,
    removedAppointment: params,
    removeCb,
  }) {
    setLoading(true);
    if (removeCb) {
      console.log(
        `Removed appointment ${params.number}, day ${params.day}, time ${params.time}, id ${params.id}`
      );
      removeCb(params.day, params.number);
    }

    setLastSelectedAppointmentDate(day);
    setLastSelectedAppointmentTime(time);
    setLastSelectedAppointmentNumber(number);

    addCb(day, number, time, id);
    setLoading(false);
  }

  async function removeAppointmentCallbackContinuousCase(
    { day, number, time, id },
    removeCb
  ) {
    setLoading(true);
    let params = { id, number, day, time };
    //await removeAppointment({ params });
    console.log(
      `Removed appointment ${number}, day ${day}, time ${time}, id ${id}`
    );
    removeCb(day, number);
    setLoading(false);
  }

  async function handleSaveButtonClick() {
    if (lastSelectedAppointmentDate) {
      try {
        const response = await axios.post("https://kidpedia.net/api/freebook", {
          day: lastSelectedAppointmentDate,
          number: lastSelectedAppointmentNumber,
          time: lastSelectedAppointmentTime,
          resDurum: 1,
        });
        toast.success("Kayıt başarılı");
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (!currentUser) {
      const timeoutId = setTimeout(() => {
        window.location.href = "/login";
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>Ücretsiz Radevu Al</title>
      </Helmet>{" "}
      {currentUser ? (
        <Container fluid>
          <ToastContainer />
          <Row>
            <Col xs={7} md={6}>
              <DayPicker
                locale={tr}
                weekStartsOn={1}
                mode="single"
                selected={date}
                onDayClick={(date) =>
                  setDate(new Date(date.getTime() + 180 * 60 * 1000))
                } // utc saat ayarı çok uğraştık sonunda bulduk
                // modifiers={modifiers}
              />
            </Col>
            <Col xs={5} md={6}>
              {appointment}
            </Col>
          </Row>
          <Row>
            <div className="mb-8 d-flex justify-content-center">
              <Button variant="success" onClick={handleSaveButtonClick}>
                Randevu Oluştur
              </Button>
            </div>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <Row className=" d-flex justify-content-center">
            {" "}
            <Card
              className="card align-items-center border "
              style={{ width: "auto", color: "black" }}
            >
              <RiAlertLine
                variant="top"
                style={{ width: "6rem", height: "6rem", color: "red" }}
              />

              <Card.Body className="border-0">
                <Card.Title className="card align-items-center fw-bolder fs-7 border-0">
                  Randevu Oluşturabilmeniz için Kayıt Olmanız Gerekmektedir.{" "}
                </Card.Title>
                <Card.Text className="card align-items-center border-0 ">
                  Sizi kayıt sayfasına yönlendiriyoruz...
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>{" "}
        </Container>
      )}
    </>
  );
}

export default Calender;
