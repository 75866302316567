import { React, useState, useEffect,useContext } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { RiEdit2Fill, RiDeleteBin7Fill,RiArrowLeftLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { AuthContext } from "../context/authContext";

const SingleEgitim = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const catId = location.pathname.split("/")[2];
  const postId = location.pathname.split("/")[3];
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
//burada id kontrolü yapmalıyız.

    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/egitim/${postId}`);
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);
console.log(post);
  if (currentUser !== null) {return (
    <div>
      <Container>
        <Row>
          <Col>
            <>
            <Button onClick={() => navigate(-1)}>
              <RiArrowLeftLine/> Geri Dön
            </Button>
              <img
                src={`../../upload/${post?.img}`}
                className="w-50 img-fluid rounded mx-auto d-block"
                alt=""
              />
            </>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <h1 className="text-center">{post.title}</h1>
          </Col>
        </Row>
    

        <Row>
          <Col className="text-justify d-grid gap-3 p-1">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.desc),
              }}
            ></div>
          </Col>
        </Row>
        {post.pdfurls && ( // Conditionally render the button only if pdfurl exists
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                href={`https://kidpedia.net/upload/pdf-upload/${post.pdfurls}`} // Adjust the path to the PDF file as needed
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
                download
                variant="success"
              >
                Pdf Dosyasını İndir
              </Button>
            </Col>
          </Row>
        )}

        
      </Container>
    </div>
  );
    
  } else {
    return (
      <h1>Bu sayfaya erişiminiz bulunmamaktadır.</h1>
    )
  }



  
};

export default SingleEgitim;
