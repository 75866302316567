import axios from 'axios'
import React, { useState, useEffect,useContext } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { AuthContext } from "../context/authContext";

const AdminContact = () => {
  const [posts, setPosts] = useState([])
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
    const res = await axios.get(`https://kidpedia.net/api/posts/getcontact/get`);
        setPosts(res.data);
      } catch (err) {
        toast.error("Postlar yüklenmedi");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  console.log(posts);

  if (currentUser?.role === "admin") {
  return (

    <div>
      <Container>
        <ToastContainer />
        <Row>
          <Col>
            <h1>Gelen Mesajlar</h1>
            {posts.map((post) => (
              <Card className="mb-2" key={post.id}>
                <Card.Body>
                  <Card.Title>{post.isim}</Card.Title>
                  <Card.Text>{post.email}</Card.Text>
                  <Card.Text>{post.tel}</Card.Text>
                  <Card.Text>{post.mesaj}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </div> )} else {
      return (
        <>
          <h1>Bu sayfayı görmeye yetkiniz yok.</h1>
        </>
      );
  
}
}

export default AdminContact;