import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Pagination} from "react-bootstrap"
import Posts from '../components/Posts'
import { Card, Button,Row,Col,Container } from 'react-bootstrap';

const KurumsalTitle = () => {
  return (
    <>
    <Container>
          <Card className="mb-4">
            <Row className="no-gutters">
              
  <Col md={12} lg={12}>
                <Card.Body >
                  <Card.Title>{"Şirketlere Yönelik Hizmetler"}</Card.Title>
                  <Card.Text>
                    <p>Kidpedia ailesi olarak şirketlere yönelik bir çok hizmetimiz bulunmaktadır. Detaylı Bilgi için Devamını Oku butonunu tıklayınız.</p>
                  </Card.Text>
                  <Button
                    variant="primary"
                    href={`/kurumsal/sirket`}
                  >
                    Devamını Oku
                  </Button>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Container>  
    
    





        <Container>
          <Card className="mb-4">
            <Row className="no-gutters">
              
  <Col md={12} lg={12}>
                <Card.Body >
              <Card.Title>{"Eğitim Kurumlarına Yönelik Hizmetler"}</Card.Title>
                  <Card.Text>
                    <p>Kidpedia ailesi olarak anaokullarına, rehabilitasyon merkezilerine ve tüm kademe eğitim veren özel okul ve özel kurumlara yönelik bir çok hizmetimiz bulunmaktadır. Detaylı Bilgi için Devamını Oku butonunu tıklayınız.</p>
                  </Card.Text>
                  <Button
                    variant="primary"
                    href={`/kurumsal/egitim`}
                  >
                    Devamını Oku
                  </Button>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Container>
    </>





 
);
};
      


export default KurumsalTitle;