import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { RiArrowLeftLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FaSchool } from "react-icons/fa";

const SingleKurumsalEgitim = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Helmet>
          <title>Eğitim Kurumlara Yönelik Hizmetler</title>
        </Helmet>
        <Container>
          <Row>
            <Col>
              <>
                <Button onClick={() => navigate(-1)}>
                  <RiArrowLeftLine /> Geri Dön
                </Button>
                <FaSchool
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "10rem",
                    width: "10rem",
                    color: "black",
                    margin: "auto",
                  }}
                />
              </>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h1 className="text-center">Eğitim Kurumlarına Yönelik Hizmetler</h1>
            </Col>
          </Row>

          <Row>
            <Col className="text-justify d-grid gap-3 p-1">
              <div>
                <p>
                  <span style={{ fontSize: "16px" }}>
                    <em>
                      Eğitim kurumlarına y&ouml;nelik bir &ccedil;ok hizmetimiz
                      bulunmaktadır. Bunlar arasında;
                    </em>
                  </span>
                </p>

                <ul>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Anaokullarına akademik destekte bulunulması ve danışmanlık
                      yapılması.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      &Ouml;zel eğitim hizmeti sunan rehabilitasyon merkezi ve
                      diğer kurumlara&nbsp;akademik destekte bulunulması ve
                      danışmanlık yapılması.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      &Ouml;ğrencilere y&ouml;nelik &ouml;l&ccedil;ek uygulama
                      &ccedil;alışmaları ve eğitimi.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Eğitim kurumlarında eğitim alan &ouml;zel gereksinimli
                      bireylere y&ouml;nelik akademik ve bilimsel destek
                      sağlanması.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Bireysel farkındalıklara y&ouml;nelik konferanslar ve
                      seminerler verilmesi.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Bireysel farklılıklara sahip &ouml;ğrencilere uygun
                      iletişim kurma y&ouml;ntemleri.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Problem davranışlarla y&ouml;nelik sağaltım planı
                      hazırlanmasına y&ouml;nelik hizmetler.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Bireysel farklılıkkları bulunan &ouml;ğrencilere
                      y&ouml;nelik g&uuml;ncel eğitim yaklaşımları.
                    </span>
                  </li>
                </ul>

                <p>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "16px" }}>
                    T&uuml;m bu &ccedil;alışmalar ve farklı &ccedil;alışmalar
                    i&ccedil;in bizimle iletişime ge&ccedil;ebilirsiniz.
                  </span>
                </p>

                <p>&nbsp;</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SingleKurumsalEgitim;
