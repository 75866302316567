import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import {  RiArrowLeftLine } from "react-icons/ri";
import {  useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdFactory } from "react-icons/md";

const SingleSirket = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Helmet>
          <title>Sirketlere Yönelik Hizmetler</title>
        </Helmet>
        <Container>
          <Row>
            <Col>
              <>
                <Button onClick={() => navigate(-1)}>
                  <RiArrowLeftLine /> Geri Dön
                </Button>
                <MdFactory className="d-flex flex-column align-items-center justify-content-center"
                  style={{ height: "10rem", width: "10rem", color: "black" , margin: "auto"}}
                />
              </>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h1 className="text-center">Şirketlere Yönelik Hizmetler</h1>
            </Col>
          </Row>

          <Row>
            <Col className="text-justify d-grid gap-3 p-1">
              <div>
                <p>
                  <span style={{ fontSize: "16px" }}>
                    <em>
                      Kurumlara y&ouml;nelik bir &ccedil;ok hizmetimiz
                      bulunmaktadır. Bunlar arasında;
                    </em>
                  </span>
                </p>

                <ul>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Kurumların &ccedil;ocuklarla ve &ouml;zel gereksinnimli
                      bireylerle iletişim kurarken dikkat etmesi gereken
                      durumlar.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Kurumların &ccedil;ocuklara y&ouml;nelik vereceği
                      hizmetlerin pedogojik uygunluğunun incelenmesi.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      &Ouml;zel gereksinimli bireylere y&ouml;nelik farkındalık
                      &ccedil;alışmaları.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      Kurum &ccedil;alışanlarının kendi &ccedil;ocuklarına
                      y&ouml;nelik uygun davranışlar sergilemesine y&ouml;nelik
                      hizmetler.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      &Ccedil;ocuk gelişim s&uuml;re&ccedil;leri hakkında
                      bilgilendirici &ccedil;alışmalar.
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "16px" }}>
                      &Ouml;zel gereksinimli bireylerin &ouml;zellikleri
                      hakkında kapsamlı bilgilendirici &ccedil;alışmalar.
                    </span>
                  </li>
                </ul>

                <p>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "16px" }}>
                    T&uuml;m bu &ccedil;alışmalar ve farklı &ccedil;alışmalar
                    i&ccedil;in bizimle iletişime ge&ccedil;ebilirsiniz.
                  </span>
                </p>

                <p>&nbsp;</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SingleSirket;
