import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import DOMPurify from "dompurify";

const Posts = ({ posts }) => {
  if (!Array.isArray(posts) || posts.length === 0) {
return <div>Veri Çekiliyor. Lütfen bir süre bekleyin. 
  Uzun süre beklediyseniz bizimle iletişime geçiniz.</div>;
  }
  return (
    <>
      {posts.map((post) => (
        <Container>
          <Card className="mb-4">
            <Row className="no-gutters">
              <Col md={4} lg={4}>
                <Card.Img
                  className="p-3 pe-4"
                  variant="top"
                  src={`https://kidpedia.net/upload/${post?.img}`}
                  style={{
                    width: "300px", // Genişlik
                    height: "150px", // Yükseklik
                  }}
                />
              </Col>
  <Col md={8} lg={8}>
                <Card.Body >
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>
                    {/* Bu kısımda hem html olorak dompurify ile yorumlattık hemde koşul ekleyerek 100 
                    karakterden fazla ise o kısmı kestik ve ... ekleyerek devamını okumaya yönlendirdik. */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          post.desc.length > 100
                            ? post.desc.substring(0, 103) + "..."
                            : post.desc
                        ),
                      }}
                    ></div>{" "}
                  </Card.Text>
                  <Button
                    variant="primary"
                    href={`/posts/${post.cat}/${post.id}/${post.title}`}
                  >
                    Devamını Oku
                  </Button>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Container>
      ))}
    </>
  );
};
export default Posts;
