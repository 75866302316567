import React from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "react-bootstrap";
import logo from "../asset/kucuklogo.png";
import { Link } from "react-router-dom";
import {
  RiFacebookCircleLine,
  RiTwitterLine,
  RiInstagramLine,
} from "react-icons/ri";

const Footer = () => {
  return (
    <Container>
      <Row className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <Col md={4} className="d-flex align-items-center">
          {" "}
          <Link
            to="/"
            className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
          >
            {" "}
            <img
              className="bi"
              width="30"
              height="24"
              src={logo}
              alt="facebook"
            ></img>
          </Link>{" "}
          <span class="mb-3 mb-md-0 text-muted">© 2023 Kidpedia.net</span>
        </Col>
        

        <Col md={4} className="justify-content-end list-unstyled d-flex">
          <Nav>
            <NavItem>
              <NavLink href="https://www.facebook.com/kidpedianet" ><RiFacebookCircleLine size={24}/>  </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://twitter.com/kidpedianet" ><RiTwitterLine size={24}/>  </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.instagram.com/kidpedia.nett/" ><RiInstagramLine size={24}/>  </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </Container>

  
  );
};

export default Footer;
