import React from "react";
import { Button, Col, Container, Row, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import dyslexia from "../asset/dyslexia.png";
import gelisim from "../asset/baby-icon.svg";
import autism from "../asset/autism.png";
import egitim from "../asset/portrait-child-school.jpg";
import { Style } from "react-style-tag";
import Bannerimg from "../asset/041.png";
import "./StyleBody.scss";
import { RiCalculatorLine } from "react-icons/ri";
import zihin from "../asset/ZYetersizlgi.png";
import Dil from "../asset/Dil.png";
import {FaChild} from "react-icons/fa"
import {MdFamilyRestroom} from "react-icons/md";
const Body = () => {
  return (
    <>
        <Style>{`
         .main-container {
          padding-top: 250px;
        }

        .header {
          position: relative;
        }

        .header-image {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }

        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          text-align: center;
          transition: background-color 0.5s;
        }
        

        .mask:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }

        .mask h2 {
          font-size: 24px;
          padding: 10px;
        }

        .header-50 {
          height: 50px;
          background-color: #333; // Header arka plan rengi
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .header-50 a {
          text-decoration: none;
          color: white;
        }

        @media (max-width: 768px) {
          .mask h2 {
            font-size: 15px;
          }
          .mask h3 {
            font-size: 17px;
          }
        }
        
      `}</Style>
<Container className="p-2 main-container">
        <Row>
          <Col>
            <div className="header">
              <img src={Bannerimg} className="header-image" alt="bannerimg" />
          <Link to="/randevu" className="mask">
                <div className="text-wrapper">
                  <h3>
                    Çocuğunuzun Gelişim Sürecine Destek Olacak Uzman Kadromuzla
                    Yanınızdayız.
                  </h3>
                  <h2>
                    Gelişim ölçekleri ve bilimsel dayanaklı uygulamalarla
                    çocuğunuzun gelişimine destek oluyoruz.
                  </h2>
                  <h2>
                    Süpervizör eşliğinde gelişim sürecine destek olmak için
                    ücretsiz randevu alabilirsiniz.
                  </h2>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 ">
        <Row className="mb-5 mt-3 border-bottom ">
          {" "}
          <h2 className="mt-4 font-weight-bolder text-center opacity-75">
            {" "}
            Hizmet Sağladığımız Alanlar
          </h2>
          <h6 className="font-weight-bolder text-center opacity-75">
            {" "}
            Daha fazla bilgi için kutucuklara tıklayın.{" "}
          </h6>{" "}
        </Row>
      </Container>

      {/* Kartlar kısmının başlangıcı */}
      <Container>
        <Row>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img
                variant="top"
                src={gelisim}
                style={{ width: "7rem", height: "7rem" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder text-center ">
                  Erken Çocukluk{" "}
                </Card.Title>
                <Card.Text>
                  <em>Beslenme Süreci</em> / <em>Emzirme Süreci</em> /{" "}
                  <em>Tuvalet Eğitimi </em>
                </Card.Text>

                <Col>
                <Button 
                    className="ms-4"
                    variant="success"
                    href="/posts/erkencocukluk"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                 
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
               <FaChild
                style={{ height: "10rem", width: "20rem", color: "black" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Ergenlik Süreci
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/ergenlikdonemi"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
                   <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <MdFamilyRestroom
                style={{ height: "10rem", width: "20rem", color: "black" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Aile Eğitimi
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/aileegitimi"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* ikinci Bölüm */}
        <Row className="mt-5">
                   <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img
                variant="top"
                src={dyslexia}
                style={{ width: "10rem", height: "10rem" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Gelişimsel Aksamalar
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/gelisimselaksamalar"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
                   <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img
                variant="top"
                src={Dil}
                style={{ width: "10rem" }}
              />
              <Card.Body>
              <Card.Title className="card align-items-center fw-bolder" style={{ fontSize: "1.4rem", textAlign:"center"}}>
  Davranış, Uyum ve İletişim Problemleri
</Card.Title>


                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/davranisproblemleri"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
                   <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
                 <Card.Img
                variant="top"
                src={autism}
                style={{ width: "10rem" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                Öğrenme Sorunları
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/ogrenmesorunlari"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/randevu">
                    Ücretsiz Randevu
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Üçüncü kartlar bölümü */}

       
      </Container>

      {/* Bilgi verme bölümü */}

      <Container className="mt-5">
        <Row className="mb-5 border-bottom ">
          {" "}
          <h2 className="font-weight-bolder text-center opacity-75">
            {" "}
            Hizmet Sürecimiz{" "}
          </h2>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row
          className="rounded"
          style={{
            background: "rgb(218,218,218)",
            background:
              "linear-gradient(90deg, rgba(218,218,218,0.5410539215686274) 3%, rgba(233,233,233,1) 53%, rgba(0,0,0,0) 91%)",
          }}
        >
          <Col sm={7}>
            <h2 className="text-center opacity-75 fw-bolder ">
              {" "}
              Gelişim Desteği
            </h2>
            <ul className="fs-5">
              <li>
                Uzmanlarımız tarafından uygun ölçek uygulanarak, seviye tespit
                edilir.{" "}
              </li>
              <ul className="border-bottom border-dark">
                <li>Agte: Gelişim düzeyi ölçeği</li>
                <li>Metropolitan okula hazırlık</li>
                <li>İhtiyaca yönelik birçok ölçek</li>
              </ul>
              <li className="border-bottom border-dark">
                Uzmanlarımız tarafından uygun, bilimsel dayanaklı  öneriler
                sunulur.
              </li>
              <li className="border-bottom  border-dark">
                Yapılan uygulamalar değerlendirilerek yol haritası çıkarılır.
              </li>
              <li className="border-bottom border-dark ">
                Tüm uygulamalar ve süreç, kayıt altına alınarak ilerlemeler
                gözlemlenir.
              </li>
            </ul>
          </Col>
          <Col sm={5} className="p-4 d-flex flex-wrap align-items-center">
            {" "}
            <Image className="rounded-3 img-fluid" src={egitim}></Image>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Body;
