import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArticleSidebar from "../components/ArticleSidebar";
import Articlescat from "../components/Articlescat";

//burası kategorilere göre makaleleri ayrıştırmak için oluşturulmuştur.
const Articles = () => {
  return (
    <>
      <Container>
        <Row>
          <Col md={3} className="border-end">
            {" "}
            <ArticleSidebar />
          </Col>
          <Col md={9}>
            {" "}
            <Articlescat />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Articles;
