import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Container,
  Table,
  Row,
  Col,
  Form,
  Modal,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { CartContext } from "../context/CartContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";

const Purchase = () => {
  const { product, totalPrice, fetchCartData } = useContext(CartContext);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [tcVergiNumarası, setTcVergiNumarası] = useState("");
  const [tel, setTel] = useState("");
  const [adres, setAdres] = useState("");
  const [şehir, setŞehir] = useState("");
  const [ilce, setIlce] = useState("");
  const [adresadi, setAdresadi] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal görünürlüğü için state
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/store/${postId}`);
      fetchCartData();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAddresses = async () => {
    try {
      const response = await axios.get("https://kidpedia.net/api/store/adres");
      setAddresses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const anasayfa = () => {
    navigate("/");
    handleSuccessModalClose();
  }

  const profile = () => {
    navigate("/profile");
    handleSuccessModalClose();
  }
  console.log(addresses);
  useEffect(() => {
    // Sayfa yüklendiğinde adresleri getir
    fetchAddresses();
  }, []); // Boş bağımlılık dizisi ile sadece bir kez çağrılacaktır

  const handleAddressSelect = (selectedValue) => {
    const selectedAddress = addresses.find(
      (address) => address.adresadi === selectedValue
    );

    if (selectedAddress) {
      setSelectedAddress(selectedAddress);
      setName(selectedAddress.name);
      setSurname(selectedAddress.surname);
      setTcVergiNumarası(selectedAddress.tcVergiNumarası);
      setTel(selectedAddress.tel);
      setAdres(selectedAddress.adres);
      setŞehir(selectedAddress.il);
      setIlce(selectedAddress.ilce);
    }
  };

  const siparisver = async () => {
    if (!selectedAddress) {
      // Adres seçimi yapılmamışsa hata mesajı göster
      toast.error('Lütfen adres seçimi yapın');
      return;
    }
  
    try {
      const selectedAddressId = selectedAddress.id;
  
      // Include the selectedAddressId as a separate parameter in the URL
      const response = await axios.post(
        `https://kidpedia.net/api/store/siparisver/${selectedAddressId}`,
        product
      );
  
      // Log the returned JSON data
      console.log(response.data);
  
      fetchCartData();
  
      // Replace the navigate() function with the appropriate navigation function for your application
      handleSuccessModalShow();
    } catch (error) {
      console.log(error);
      toast.error('Bir hata oluştu. Lütfen iletişim bölümünden bizimle iletişime geçiniz.');
    }
  };
  const handleSuccessModalShow = () => setShowSuccessMessage(true);
  const handleSuccessModalClose = () => setShowSuccessMessage(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleSaveAddress = async () => {
    try {
      await axios.post("https://kidpedia.net/api/store/addadres", {
        adresadi, // Örnek olarak adres değerini kullanıyoruz, ihtiyaca göre diğer değerleri de ekleyebilirsiniz
        name,
        surname,
        tcVergiNumarası,
        tel,
        adres,
        şehir,
        ilce,
      });
      // Adres eklendikten sonra adresleri tekrar çekip listeyi güncelliyoruz
      fetchAddresses();
      setShowModal(false); // Modal'ı kapatıyoruz
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sepet</title>
      </Helmet>
      <Container>
        <ToastContainer/>
        <Row>
          <Col md={8}>
            <p>Sepetinizdeki Ürünler</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Ürün Kodu</th>
                  <th>Ürün Adı</th>
                  <th>Fiyatı</th>
                  <th>Sil</th>
                </tr>
              </thead>
              <tbody>
                {product.map((post, index) => (
                  <tr key={post.id}>
                    <td>{index + 1}</td>
                    <td>{post.urunadi}</td>
                    <td>{post.fiyat + "₺"}</td>
                    <td>
                      <RiDeleteBin7Fill
                        onClick={() => handleDelete(post.id)}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h1>Toplam: {totalPrice}₺</h1>
          </Col>
          <Col md={4}>
            <div>
              <h2>Adres</h2>{" "}
              <Form.Group>
                <Row>
                  <Col xs={6}>
                    <Dropdown
                      onSelect={(eventKey) => handleAddressSelect(eventKey)}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        size="sm"
                        id="dropdown-basic"
                      >
                        {selectedAddress
                          ? selectedAddress.adresadi
                          : "Adres Seç"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu size="sm">
                        <Dropdown.Item eventKey="">Adres Seç</Dropdown.Item>
                        {addresses.map((address) => (
                          <Dropdown.Item
                            key={address.adresadi}
                            eventKey={address.adresadi}
                          >
                            {address.adresadi}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={6} className="mb-2">
                    <Button className="btn-sm" onClick={handleModalShow}>
                      Adres Ekle
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="İsim"
                  value={name}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Soyisim"
                  value={surname}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="TC/Vergi Numarası"
                  value={tcVergiNumarası}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Tel"
                  value={tel}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Adres"
                  value={adres}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Şehir"
                  value={şehir}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="İlçe"
                  value={ilce}
                  readOnly
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Button
          className="btn-block mt-3"
          onClick={siparisver}
          handleSuccessModalClose
          block
          type="submit"
        >
          Satın Al
        </Button>
      </Container>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adres Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Adres Adı"
              value={adresadi}
              onChange={(e) => setAdresadi(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="İsim"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Soyisim"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="TC/Vergi Numarası"
              value={tcVergiNumarası}
              onChange={(e) => setTcVergiNumarası(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Tel"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Adres"
              value={adres}
              onChange={(e) => setAdres(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Şehir"
              value={şehir}
              onChange={(e) => setŞehir(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="İlçe"
              value={ilce}
              onChange={(e) => setIlce(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  onClick={handleModalClose}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleSaveAddress}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccessMessage} onHide={handleSuccessModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Başarılı</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Siparişiniz başarı ile alındı. Siparişinizi Profilim sayfasında yer
          alan Randevular kısmından görebilirsiniz. Teşekkürler.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={anasayfa}>Ana Sayfaya Dön</Button>
          <Button variant="primary" onClick={profile} >Profil Sayfasına Git</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Purchase;
