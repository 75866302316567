import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Pagination,
  Modal,
} from "react-bootstrap";
import AdminHeader from "../components/Admin/AdminHeader";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../context/authContext";
import { CartContext } from "../context/CartContext";
import { Helmet } from "react-helmet";
const PAGE_SIZE = 5;

const Store = () => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { currentUser } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {fetchCartData } = useContext(CartContext);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/store`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const refreshPage = ()=> {
    window.location.reload(false);
  }
  

  const saveUserSelection = async (postId) => {
    try {
      // Make a request to save the selected user ID with the post ID
      const response = await axios.post (`https://kidpedia.net/api/store`,{
        postId,
      });
      // Check the status of the response and show a toast message accordingly
        toast.success("Sepete Eklendi");
        fetchCartData();
    } catch (err) {
      console.log(err);
      toast.error("Bir Hata Oluştu.");
    }
  };




  
  const filteredPosts = category
    ? posts.filter((post) => post.cat === category)
    : posts;

  const totalPages = Math.ceil(filteredPosts.length / PAGE_SIZE);
  const visiblePosts = filteredPosts.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  // Helper function to iterate over array in pairs
  const pairwise = (arr) => {
    const result = [];
    for (let i = 0; i < arr.length; i += 2) {
      result.push(arr.slice(i, i + 2));
    }
    return result;
  };

  // Group the visiblePosts in pairs
  const pairedPosts = pairwise(visiblePosts);

  return (
    <>
<Helmet> <title>Mağaza</title></Helmet>
      <Container>
    <ToastContainer position="top-center"/>
        {pairedPosts.map((pair, index) => (
          <Row key={index}>
            {pair.map((post) => (
              <Col key={post.id} md={6} lg={6}>
                <Card className="mb-4">
                  <Row className="no-gutters">
                    <Col md={2} lg={2}>
                      <Card.Img
                        className="p-2"
                        variant="top"
                        src={`https://kidpedia.net/upload/${post?.img}`}
                      />
                    </Col>
                    <Col>
                      <Card.Body>
                        <Card.Title>{post.urunadi}</Card.Title>
                        <Card.Text>
                          <Row>
                            <Col
                              sm={10}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                   post.urunbilgileri
                                ),
                              }}
                            ></Col>
                            <Col
                              sm={10}
                              
                                  
                                
                              
                            > <h5>{post.fiyat + "₺"}</h5> </Col>{" "}
                           
                          </Row>
                        </Card.Text>
               {currentUser !== null ? <Button
                          variant="warning"
                          onClick={() => saveUserSelection(post.id)}
                        >
                          Sepete Ekle</Button> :<> <Button variant="warning" onClick={handleShow}>
Sepete Ekle      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Giriş yapmalısınız</Modal.Title>
        </Modal.Header>
        <Modal.Body>Eğitim danışmanlığı edinebilmeniz için giriş yapmalısınız. Lütfen Giriş yap gölümünden giriş yapınız</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
      <Button href="/login" variant="primary" onClick={handleClose}>
            Giriş Yap
          </Button>
        </Modal.Footer>
      </Modal></>} 
                       
                       
                       
                       
                       
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        ))}
        <div>
          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </Container>
    </>
  );
};

export default Store;