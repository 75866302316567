import React, { useContext,useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { CartContext } from "../context/CartContext";

const Cart = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { product, totalPrice, fetchCartData } = useContext(CartContext);

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/store/${postId}`);
      fetchCartData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button onClick={handleShow}> Sepet ({product.length})</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alişveriş Sepeti</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {product.length > 0 ? (
            <>
              <p>Sepetinizdeki Ürünler</p>
              <Table striped bordered condensed hover>
                <thead>
                  <tr>
                    <th>Ürün Kodu</th>
                    <th>Ürün Adı</th>
                    <th>Fiyatı</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {product.map((post, index) => (
                    <tr key={post.id}>
                      <td>{index + 1}</td>
                      <td>{post.urunadi}</td>
                      <td>{post.fiyat + "₺"}</td>
                      <td>
                        <RiDeleteBin7Fill
                          onClick={() => handleDelete(post.id)}
                          style={{
                            height: "1rem",
                            width: "1rem",
                            color: "black",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h1>Toplam: {totalPrice}₺</h1>
          <Button href={"/purchase"} variant="success">Satın Al</Button>
            </>
          ) : (
            <h1>Sepetinizde Ürün Yok Yada Giriş Yapmadınız!</h1>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Cart;
