import React from 'react'
import { Col, Container, Row,Image } from 'react-bootstrap';
import profileimg from "../asset/profile.svg"





const posts = [
      {
        id: 1,
        title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. A possimus excepturi aliquid nihil cumque ipsam facere aperiam at! Ea dolorem ratione sit debitis deserunt repellendus numquam ab vel perspiciatis corporis!",
        img: profileimg
      },
      {
        id: 2,
        title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. A possimus excepturi aliquid nihil cumque ipsam facere aperiam at! Ea dolorem ratione sit debitis deserunt repellendus numquam ab vel perspiciatis corporis!",
        img: profileimg,
      },
      {
        id: 3,
        title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. A possimus excepturi aliquid nihil cumque ipsam facere aperiam at! Ea dolorem ratione sit debitis deserunt repellendus numquam ab vel perspiciatis corporis!",
        img: profileimg,
      },
      {
        id: 4,
        title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. A possimus excepturi aliquid nihil cumque ipsam facere aperiam at! Ea dolorem ratione sit debitis deserunt repellendus numquam ab vel perspiciatis corporis!",
        img: profileimg,
      },
    ];



const uzmanlar = () => {
  return (
    <>
    {posts.map((post) => (
           <Container className="mt-5 ">
              <Row key={post.id} 
      style={{
        background:
          "radial-gradient(circle at 0% 0.5%, rgb(241, 241, 242) 0.1%, rgb(224, 226, 228) 100.2%)",
      }}
          >
      <Col sm={7}>
        <h2 className="text-center opacity-75 "> Sayın {post.title}</h2>
        <ul className="fs-5">
          <li>
            {post.desc}
          </li>
         </ul>
      </Col>
      <Col sm={5} className="p-4 d-flex flex-wrap align-items-center justify-content-center ">
        {" "}
        <Image style={{height: "300px", width:"250px"}} className="rounded-3 img-fluid " src={post.img}></Image>
      </Col>
         </Row>
            </Container>

    ))}
    </>
  )}

export default uzmanlar