import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Pagination,
  FormSelect,
} from "react-bootstrap";
import AdminHeader from "../components/Admin/AdminHeader";
import DOMPurify from "dompurify";
import { RiEdit2Fill, RiDeleteBin7Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { ToastContainer, toast } from "react-toastify";

const PAGE_SIZE = 5;

const EgitimOneri = () => {
  const { currentUser } = useContext(AuthContext);

  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/egitim`);
        setPosts(res.data);
      } catch (err) {
        toast.error("Bu içeriği görmeye yetkiniz yok");

        console.log(err);
      }
    };
    fetchData();
  }, []);

  const refreshPage = () => {
    navigate(0);
  };
  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/egitim/${postId}`);
      refreshPage();
    } catch (err) {
      console.log(err);
    }
  };

  console.log(posts)

  const filteredPosts = category
    ? posts.filter((post) => post.cat === category)
    : posts;

  const totalPages = Math.ceil(filteredPosts.length / PAGE_SIZE);
  const visiblePosts = filteredPosts.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  if (currentUser !== null) {
    if (posts.length === 0) {
      {
        toast.error("Henüz eğitim önerisi girilmemiş", {
          toastId: "error1",
        });
      }

      return (
        <>
          <ToastContainer />
          <Container>
            <h3>Henüz eğitim önerisi girilmemiş.</h3>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Container>
            <ToastContainer />

            <Row>
              {visiblePosts.map((post) => (
                <>
                  <Container>
                    <Card className="mb-4">
                      <Row className="no-gutters">
                        <Col md={2} lg={2}>
                          <Card.Img
                            className="p-2"
                            variant="top"
                            src={`https://kidpedia.net/upload/${post?.img}`}
                          />
                        </Col>
                        <Col>
                          <Card.Body>
                            <Card.Title>{post.title}</Card.Title>
                            <Card.Text>
                              {/* Bu kısımda hem html olorak dompurify ile yorumlattık hemde koşul ekleyerek 100 
                karakterden fazla ise o kısmı kestik ve ... ekleyerek devamını okumaya yönlendirdik. */}
                              <Row>
                                <Col
                                  sm={10}
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      post.desc.length > 100
                                        ? post.desc.substring(0, 103) + "..."
                                        : post.desc
                                    ),
                                  }}
                                ></Col>{" "}
                                
                              </Row>
                            </Card.Text>
                            {currentUser.role === "admin" ? (
              <Button
                variant="primary"
                href={`/egitim/${post.cat}/${post.id}`}
              >
                Devamını Oku (Admin)
              </Button>
            ) : (
              <Button
                variant="primary"
                href={`/egitim/${post.cat}/${post.egitimid}`}
              >
                Devamını Oku
              </Button>
            )}
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  </Container>
                </>
              ))}
            </Row>

            <div>
              <Pagination>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </Container>
        </>
      );
    }
  } else {
    return (
      <>
        <h1>Bu sayfayı görmeye yetkiniz yok.</h1>
      </>
    );
  }
};

export default EgitimOneri;
