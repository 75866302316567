import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import AdminHeader from "../components/Admin/AdminHeader";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Pagination,
  FormSelect,
} from "react-bootstrap";
import DOMPurify from "dompurify";
import { RiEdit2Fill, RiDeleteBin7Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";

const PAGE_SIZE = 5;

const AdminFreeGorusme = () => {
  const { currentUser } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState();

  const navigate = useNavigate();

  //Veri Çekme
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/freebook`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  console.log(posts);

  const refreshPage = () => {
    navigate(0);
  };

  //Veri Yazma
  const handleSave = async (postId) => {
    try {
      await axios.put(`https://kidpedia.net/api/freebook/${postId}`, {
        url: value,
      });

      refreshPage();
    } catch (err) {
      console.log(err);
      console.log(postId);
    }
  };

  const getSerialNumber = (postId) => {
    const index = posts.findIndex((post) => post.id === postId);
    const serialNumber = (currentPage - 1) * PAGE_SIZE + index + 1;
    return serialNumber;
  };

  // Sort the posts in descending order based on the date
  const sortedPosts = posts.sort((a, b) => b.id - a.id);


  // admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
      return (
        <>
          <AdminHeader />
          <Container>
            <Row>
              {sortedPosts.map((post) => (
                <>
                  <Container>
                    <Card className="mb-4">
                      <Row className="no-gutters">
                        <Col>
                          <Card.Body>
                            <Card.Title>
                              {getSerialNumber(post.id)} ID: {post.id} {" "}
                              {new Date(
                                new Date(post.date).getTime() +
                                  3 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .slice(0, 10)}{" "}
                              {post.time} {post.firstname} {post.lastname}{" "}
                              
                            </Card.Title>
                            <Card.Text>
                              {/* Bu kısımda hem html olorak dompurify ile yorumlattık hemde koşul ekleyerek 100 
                    karakterden fazla ise o kısmı kestik ve ... ekleyerek devamını okumaya yönlendirdik. */}
                              <Row>
                                <ReactQuill
                                  theme="snow"
                                  defaultValue={post.url}
                                  onChange={setValue}
                                  className="h-75 "
                                />
                              </Row>
                            </Card.Text>
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() => handleSave(post.id)}
                            >
                              Kaydet
                            </Button>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  </Container>
                </>
              ))}
            </Row>
          </Container>
        </>
      );
    case 503422:
      return <div>Moderator</div>;
    case 503439:
      return <div>BackendUser</div>;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default AdminFreeGorusme;
