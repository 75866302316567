import { React, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      toast.success("Giriş Başarılı");
      navigate("/");
    } catch (err) {
      toast.error(err.response.data);
      setError(err.response.data);
      console.log(err.response.data);
    }
  };
  return (
    <>
      <ToastContainer />
      <Helmet>
        {" "}
        <title>Giriş Yap</title>
      </Helmet>
      <div
        className="container py-5 h-100"
        style={{ width: "auto", color: "black" }}
      >
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-5 text-center">
                <h3 className="mb-5">Giriş Yap</h3>
                <div className="form-outline mb-4">
                  <input
                    name="email"
                    type="email"
                    id="typeEmailX-2"
                    className="form-control form-control-lg"
                    onChange={handleChange}
                  />
                  <label className="form-label" for="typeEmailX-2">
                    Email
                  </label>
                </div>
                <div className="form-outline mb-4">
                  <input
                    name="password"
                    type="password"
                    id="typePasswordX-2"
                    className="form-control form-control-lg"
                    onChange={handleChange}
                  />
                  <label className="form-label" for="typePasswordX-2">
                    Parola
                  </label>
                </div>
                {/*                         <!-- Checkbox -->
                 */}{" "}
                <button
                  className="btn btn-primary btn-lg me-1"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Giriş
                </button>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => navigate("/register")}
                >
                  Kayıt Ol
                </button>
                {err && <p>{err}</p>}
                <hr className="my-4" />
                <Link to={"/recovery"}>
                  <div>Şifremini Unuttum</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
