import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavbarBrand,
  NavLink,
  NavItem,
  Button,
} from "react-bootstrap";
import bootstaplogo from "../asset/logo.png";
import {
  RiMailSendLine,
  RiLoginBoxFill,
  RiPencilFill,
  RiLogoutBoxFill,
} from "react-icons/ri";
import { AuthContext } from "../context/authContext";
import Cart from "./Cart";

const Header = () => {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <div>
      <Container>
        <Navbar
          href="/"
          bg="light"
          expand="md"
          fixed="top"
          className=" mb-4 border-bottom"
        >
          <NavbarBrand
            href="/"
            className=" ps-5 d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
          >
            <img
              alt="kidpedia"
              className="img-fluid"
              width="150"
              height="auto"
              src={bootstaplogo}
            ></img>
          </NavbarBrand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end ">
            <Nav className="d-flex align-items-center">
              <NavItem>
                <NavLink className={url === "/" ? " active" : ""} href="/">
                  {" "}
                  AnaSayfa
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={url === "/posts" ? " active" : ""}
                  href="/posts/"
                >
                  Makaleler
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={url === "/randevu" ? " active" : ""}
                  href="/randevu"
                >
                  Randevu
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={url === "/uzmanlar" ? " active" : ""}
                  href="/uzmanlar"
                >
                  {" "}
                  Uzmanlar
                </NavLink>
              </NavItem>
 {currentUser ? (<NavItem>
                <NavLink
                  className={url === "/profile" ? " active" : ""}
                  href="/profile"
                >
                  Profile
                </NavLink>
              </NavItem>):null}
              
              <NavItem>
                <NavLink
                  className={url === "/kurumsal" ? " active" : ""}
                  href="/kurumsal"
                >
                  Kurumsal
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={url === "/about" ? " active" : ""}
                  href="/About"
                >
                  Hakkında
                </NavLink>
              </NavItem> */}

              {currentUser ? (
                currentUser.cat === 503450 ? (
                  <NavItem>
                    <NavLink
                      className={url === "/about" ? " active" : ""}
                      href="/admin"
                    >
                      Yönetim
                    </NavLink>
                  </NavItem>
                ) : null
              ) : null}
            </Nav>
            {currentUser ? (
              <NavItem class="font-weight-bold text-capitalize p-1 ">
                {" "}
                Hoşgeldin {currentUser.firstname + " " + currentUser.lastname}
              </NavItem>
            ) : null}

            {currentUser ? (
              <>
              <Button variant="danger" onClick={logout} className="px-1 me-1">
                <RiLogoutBoxFill /> Çıkış{" "}
              </Button>
                <Cart /></>
            ) : (
              <>
                <Button
                  variant="success"
                  className="px-1 me-1"
                  href="/register"
                >
                  <RiPencilFill /> Kayıt Ol{" "}
                </Button>{" "}
                <Button variant="primary" className="px-1 me-1" href="/login">
                  <RiLoginBoxFill /> Giriş{" "}
                </Button>
              
              </>
            )}
           
            <Button variant="warning" href="/iletisim" className="px-3 me-2 ">
              <RiMailSendLine /> İletişim{" "}
            </Button>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
