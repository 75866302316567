import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import AdminHeader from "../components/Admin/AdminHeader";

const Update = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const location = useLocation();
  const defaultUser = `${currentUser.firstname} ${currentUser.lastname}`;

  const state = useLocation();
  const [title, setTitle] = useState(state?.title || "");
  const [value, setValue] = useState(state?.desc || "");
  const [file, setFile] = useState(null);
  const [img,setImg] = useState(state?.img)
  const [cat, setCat] = useState(state?.cat || "");
  const [publishInMagazine, setPublishInMagazine] = useState(
    state?.publishInMagazine
  ); // New state for the checkbox
  const [makale, setMakale] = useState(state?.makale);
  const [authorName, setAuthorName] = useState(defaultUser);
  const [authorTitle, setAuthorTitle] = useState("");
  const [month, setMonth] = useState("");
  const id = location.pathname.split("/")[2];
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://kidpedia.net/api/posts/${id}`)
      .then((response) => {
        setTitle(response.data.title);
        setValue(response.data.desc);
        setFile(response.data.img);
        setCat(response.data.cat);
        setPublishInMagazine(response.data.publishInMagazine);
        setMakale(response.data.makale);
        setAuthorName(response.data.yazaradi);
        setAuthorTitle(response.data.yazarunvan);
        setMonth(response.data.month);
        setImg(response.data.img)
      
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(`https://kidpedia.net/api/upload`, formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  console.log(img)

  const handleClick = async (e) => {
    e.preventDefault();
    let fileUrl =  await upload(); 
   console.log(fileUrl)// If a new file is selected, upload it and get the URL, else use the existing URL
    try {
      await axios.put(`https://kidpedia.net/api/posts/${id}`, {
        title,
        desc: value,
        cat,
        img: fileUrl?.length > 0 ? fileUrl : img,
        date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        ucad: currentUser.cat,
        uid: currentUser.ID,
        publishInMagazine: publishInMagazine, // Include the checkbox state
        makaleyayinla: makale, // Include the checkbox state
        authorName,
        authorTitle,
        month,
      });

      navigate("/admin/adminposts");
    } catch (err) {
      console.log(err);
    }
  };

  // admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
    case 503422:
      return (
        <>
          <AdminHeader />
          <Container>
            <Form>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Başlık"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />

                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    className="h-75 "
                  />
                </Col>

                <Col sm={3}>
                  <div className="menu">
                    <div className="item">
                      <h4>Yayınla</h4>

                      <Form.Group
                        controlId="formFile"
                        className="mb-2"
                        id="file"
                        name=""
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          Resim Yükle
                        </Form.Label>
                        <Form.Control type="file" />
                      </Form.Group>

                      <div className="buttons">
                        <button onClick={handleClick}>Publish</button>
                      </div>
                    </div>
                    <div className="item">
                      <h4>Kategoriler</h4>
                      <div className="cat">
                        {[
                          "erkencocukluk",
                          "ergenlikdonemi",
                          "aileegitimi",
                          "gelisimselaksamalar",
                          "davranisproblemleri",
                          "ogrenmesorunlari",
                        ].map((category) => (
                          <div key={category}>
                            <input
                              type="radio"
                              name="cat"
                              value={category}
                              id={category}
                              onChange={(e) => setCat(e.target.value)}
                              checked={cat === category}
                            />
                            <label htmlFor={category}>{category}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="item">
                      <h5>Dergide Yayınla</h5>
                      <input
                        type="checkbox"
                        checked={publishInMagazine === 1}
                        onChange={(e) =>
                          setPublishInMagazine(e.target.checked ? 1 : 0)
                        } // Set 1 if checked, otherwise set 0
                      />
                    </div>
                    <div className="item">
                      <h5>makaledelede Yayınla</h5>
                      <input
                        type="checkbox"
                        checked={makale === 1}
                        onChange={(e) => setMakale(e.target.checked ? 1 : 0)} // Set 1 if checked, otherwise set 0
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Yazar Adı"
                    className="form-control mt-2"
                    value={authorName}
                    onChange={(e) => {
                      setAuthorName(e.target.value);
                    }}
                  />

                  <input
                    type="text"
                    placeholder="Yazar Ünvanı"
                    className="form-control mt-2"
                    value={authorTitle}
                    onChange={(e) => setAuthorTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Tarih Örn: Eylül 2023"
                    className="form-control mt-2"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      );
      break;

    case 503439:
      return <div>BackendUser</div>;
      break;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default Update;
