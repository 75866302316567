import { React, useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import AdminHeader from "../components/Admin/AdminHeader";
import { Container, Row, Image, Col } from "react-bootstrap";
import img from "../asset/oc-collaboration.svg";

const Admin = () => {
  const { currentUser } = useContext(AuthContext);

  // admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
      return (
        <>
          <AdminHeader />
          <Container fluid>
            <Row>
              <Col>
                <div className="border d-flex align-items-center justify-content-center">
                  {" "}
                  <Image src={img} fluid width="50%" rounded />
                </div>
              </Col>
              <Row>
                <h1
                  style={{ color: "21325b", fontWeight: "bold" }}
                  className="mt-2 d-flex justify-content-center"
                >
                  Yönetim Sayfamıza Hoşgeldiniz
                </h1>
              </Row>
            </Row>
          </Container>
        </>
      );
      break;

    case 503422:
      return <div>Moderator</div>;
      break;

    case 503439:
      return <div>BackendUser</div>;
      break;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default Admin;
