import React, { useState} from "react";
import Axios from "axios";
import { Container, Row, Col,Modal,Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
const Register = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // Modalı açıp kapamak için bir state kullanın

  Axios.defaults.withCredentials = true;


  // Axios configuration for your base URL
  Axios.defaults.baseURL = "https://kidpedia.net/api/"; // Adjust the URL as needed

  // Form validation schema using Yup
  const validate = Yup.object({
    firstName: Yup.string()
      .min(2, "Çok Kısa!")
      .max(15, "En fazla 15 karakter olmalı")
      .required("Zorunlu"),
    lastName: Yup.string()
      .max(20, "En fazla 20 karakter olmalı")
      .required("Zorunlu"),
    email: Yup.string().email("Geçerli bir e-posta değil").required("E-posta zorunlu"),
    phone: Yup.string()
      .matches(/^\d{11}$/, "Telefon numarası 11 haneli olmalı")
      .required("Telefon numarası zorunlu"),
    password: Yup.string()
      .min(6, "Parola en az 6 karakter olmalı")
      .required("Parola zorunlu"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Parola eşleşmiyor")
      .required("Parola tekrarı zorunlu"),
    acceptTerms: Yup.bool()
      .oneOf([true], "Şartları ve koşulları kabul etmelisiniz")
      .required("Şartları ve koşulları kabul etmelisiniz"),
  });

  return (
    <>
      <Helmet><title>Kayıt Ol</title></Helmet>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg="6">
            <ToastContainer />
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "", // Added phone number field
                password: "",
                confirmPassword: "",
                acceptTerms: false,

              }}
              validationSchema={validate}
              onSubmit={(values) => {
            Axios.post("auth/register", values)
                  .then((res) => {
                    if (res.status === 200) {
                      toast.success("Kaydınız Yapıldı Giriş Yapabilirsiniz!");
                      setTimeout(() => {
                        navigate("/login");
                      }, 5000);
                    }
                  })
                  .catch((err) => {
                    if (err.response && err.response.status === 409) {
                      toast.error("Kullanıcı daha önce kaydolmuş!");
                      toast.error("Giriş sayfasına yönlendiriliyorsunuz");
                      setTimeout(() => {
                        navigate("/login");
                      }, 5000);
                    } else {
                      toast.error("Bir hata oluştu.");
                    }
                    console.log(err);
                  });
              }}
            >
              {({ errors, touched, resetForm, isSubmitting, isValidating }) => (
                <Form>
                  <div className="form-group">
                    <label className="m-2">Adınız</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={
                        "form-control" +
                        (errors.firstName && touched.firstName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastName" className="m-2">
                      {" "}
                      Soyadınız{" "}
                    </label>
                    <Field
                      name="lastName"
                      type="text"
                      className={
                        "form-control" +
                        (errors.lastName && touched.lastName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label className="m-2" htmlFor="email">
                      {" "}
                      Email{" "}
                    </label>
                    <Field
                      name="email"
                      type="email"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label className="m-2" htmlFor="phone">
                      {" "}
                      Telefon Numarası{" "}
                    </label>
                    <Field
                      name="phone"
                      type="tel"
                      placeholder="05553332211 örnekteki gibi giriniz." // Placeholder for phone number
                      className={
                        "form-control" + (errors.phone && touched.phone ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                  </div>


                  <div className="form-group">
                    <label className="m-2" htmlFor="password">
                      {" "}
                      Parola{" "}
                    </label>
                    <Field
                      name="password"
                      type="password"
                      className={
                        "form-control" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label className="m-2" htmlFor="confirmPassword">
                      {" "}
                     Parola Tekrar Giriniz{" "}
                    </label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      className={
                        "form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group form-check">
                    <Field
                      name="acceptTerms"
                      type="checkbox"
                      className={
                        "form-check-input" +
                        (errors.acceptTerms && touched.acceptTerms
                          ? " is-invalid"
                          : "")
                      }
                    />
                 <label
                      htmlFor="acceptTerms"
                      className="form-check-label"
                      onClick={() => setShowModal(true)} // Modalı açmak için tıklanabilir bir metin ekleyin
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      Sözleşme Metnini Okudum.
                    </label>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Kayıt Ol
                    </button>
                    <button
                      type="button"
                      onClick={resetForm}
                      className="btn btn-warning float-right"
                    >
                      Reset
                    </button>
                  </div>
                  <div>
                    <label className="mt-3">
                      Hesabınız var mı ?<Link to="/login">Giriş</Link>
                    </label>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
       {/* Sözleşme metni modalı */}
       <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kullanım Koşulları</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Kullanım Koşulları
              </span>
            </span>
          </h2>
          <p>&nbsp;</p>
          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  Sevgili ziyaretçimiz, lütfen{" "}
                  <a href="https://kidpedia.net">https://kidpedia.net</a> web
                  sitemizi ziyaret etmeden önce işbu kullanım koşulları
                  sözleşmesini dikkatlice okuyunuz. Siteye erişiminiz tamamen bu
                  sözleşmeyi kabulünüze ve bu sözleşme ile belirlenen şartlara
                  uymanıza bağlıdır. Şayet bu sözleşmede yazan herhangi bir
                  koşulu kabul etmiyorsanız, lütfen siteye erişiminizi
                  sonlandırınız. Siteye erişiminizi sürdürdüğünüz takdirde,
                  koşulsuz ve kısıtlamasız olarak, işbu sözleşme metninin
                  tamamını kabul ettiğinizin, tarafımızca varsayılacağını lütfen
                  unutmayınız.
                </span>
              </span>
            </span>
          </p>

          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Gizlilik
              </span>
            </span>
          </h2>
          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  Gizlilik, ayrı bir sayfada, kişisel verilerinizin tarafımızca
                  işlenmesinin esaslarını düzenlemek üzere mevcuttur. SİTE'yi
                  kullandığınız takdirde, bu verilerin işlenmesinin gizlilik
                  politikasına uygun olarak gerçekleştiğini kabul edersiniz.
                </span>
              </span>
            </span>
          </p>

          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Hizmet Kapsamı
              </span>
            </span>
          </h2>
          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  Şirket Adı olarak, sunacağımız hizmetlerin kapsamını ve
                  niteliğini, yasalar çerçevesinde belirlemekte tamamen serbest
                  olup; hizmetlere ilişkin yapacağımız değişiklikler, SİTE'de
                  yayınlanmakla yürürlüğe girmiş sayılacaktır.
                </span>
              </span>
            </span>
          </p>

          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Telif Hakları
              </span>
            </span>
          </h2>
          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  SİTE'de yayınlanan tüm metin, kod, grafikler, logolar,
                  resimler, ses dosyaları ve kullanılan yazılımın sahibi (bundan
                  böyle "içerik" olarak anılacaktır) Şirket Adı olup, tüm
                  hakları saklıdır. Yazılı izin olmaksızın site içeriğinin
                  çoğaltılması veya kopyalanması kesinlikle yasaktır.
                </span>
              </span>
            </span>
          </p>

          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Genel Hükümler
              </span>
            </span>
          </h2>
          <ul>
            <li>
              <p>
                <span style={{ color: "#4a4a4a" }}>
                  <span
                    style={{
                      fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "medium" }}>
                      Kullanıcıların tamamı, SİTE'yi yalnızca hukuka uygun ve
                      şahsi amaçlarla kullanacaklarını ve üçüncü kişinin
                      haklarına tecavüz teşkil edecek nitelikteki herhangi bir
                      faaliyette bulunmayacaklarını taahhüt eder. SİTE dâhilinde
                      yaptıkları işlem ve eylemlerindeki, hukuki ve cezai
                      sorumlulukları kendilerine aittir. İşbu iş ve eylemler
                      sebebiyle, üçüncü kişilerin uğradıkları veya
                      uğrayabilecekleri zararlardan dolayı SİTE'nin doğrudan
                      ve/veya dolaylı hiçbir sorumluluğu yoktur.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ color: "#4a4a4a" }}>
                  <span
                    style={{
                      fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "medium" }}>
                      SİTE'de mevcut bilgilerin doğruluk ve güncelliğini
                      sağlamak için elimizden geleni yapmaktayız. Lakin
                      gösterdiğimiz çabaya rağmen, bu bilgiler, fiili
                      değişikliklerin gerisinde kalabilir, birtakım farklılıklar
                      olabilir. Bu sebeple, site içerisinde yer alan bilgilerin
                      doğruluğu ve güncelliği ile ilgili tarafımızca, açık veya
                      zımni, herhangi bir garanti verilmemekte, hiçbir taahhütte
                      bulunulmamaktadır.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ color: "#4a4a4a" }}>
                  <span
                    style={{
                      fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "medium" }}>
                      SİTE'de üçüncü şahıslar tarafından işletilen ve içerikleri
                      tarafımızca bilinmeyen diğer web sitelerine, uygulamalara
                      ve platformlara köprüler (hyperlink) bulunabilir. SİTE,
                      işlevsellik yalnızca bu sitelere ulaşımı sağlamakta olup,
                      içerikleri ile ilgili hiçbir sorumluluk kabul
                      etmemekteyiz.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ color: "#4a4a4a" }}>
                  <span
                    style={{
                      fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "medium" }}>
                      SİTE'yi virüslerden temizlenmiş tutmak konusunda elimizden
                      geleni yapsak da, virüslerin tamamen bulunmadığı
                      garantisini vermemekteyiz. Bu nedenle veri indirirken,
                      virüslere karşı gerekli önlemi almak, kullanıcıların
                      sorumluluğundadır. Virüs vb. kötü amaçlı programlar,
                      kodlar veya materyallerin sebep olabileceği zararlardan
                      dolayı sorumluluk kabul etmemekteyiz.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ color: "#4a4a4a" }}>
                  <span
                    style={{
                      fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                    }}
                  >
                    <span style={{ fontSize: "medium" }}>
                      SİTE'de sunulan hizmetlerde, kusur veya hata olmayacağına
                      ya da kesintisiz hizmet verileceğine dair garanti
                      vermemekteyiz. SİTE'ye ve sitenin hizmetlerine veya
                      herhangi bir bölümüne olan erişiminizi önceden
                      bildirmeksizin herhangi bir zamanda sonlandırabiliriz.
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <h2>
            <span style={{ color: "#363636" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                Sorumluluğun Sınırlandırılması
              </span>
            </span>
          </h2>
          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  SİTE'nin kullanımından doğan zararlara ilişkin sorumluluğumuz,
                  kast ve ağır ihmal ile sınırlıdır. Sözleşmenin ihlalinden
                  doğan zararlarda, talep edilebilecek toplam tazminat,
                  öngörülebilir hasarlar ile sınırlıdır. Yukarıda bahsedilen
                  sorumluluk sınırlamaları aynı zamanda insan hayatına, bedeni
                  yaralanmaya veya bir kişinin sağlığına gelebilecek zararlar
                  durumunda geçerli değildir. Hukuken mücbir sebep sayılan tüm
                  durumlarda, gecikme, ifa etmeme veya temerrütten dolayı,
                  herhangi bir tazminat yükümlülüğümüz doğmayacaktır.
                </span>
              </span>
            </span>
          </p>

          <p>
            <span style={{ color: "#4a4a4a" }}>
              <span
                style={{
                  fontFamily:
                    "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
                }}
              >
                <span style={{ fontSize: "medium" }}>
                  Uyuşmazlık Çözümü: İşbu Sözleşme'nin uygulanmasından veya
                  yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde,
                  Türkiye Cumhuriyeti yasaları uygulanır.
                </span>
              </span>
            </span>
          </p>

          <p>&nbsp;</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Register;
