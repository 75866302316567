import React, { useState } from 'react';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post('https://kidpedia.net/api/auth/forgot-password', { email });
      setMessage(response.data); // API'den gelen mesajı ayarla
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMessage('Kullanıcı bulunamadı.'); // 404 durumunda kullanıcı bulunamadı
      } else {
        setMessage('Hata oluştu.'); // Diğer hata durumları için genel hata mesajı
      }
    }
  };

  return (
    <div>
      <h2>Şifremi Unuttum</h2>
      <p>Şifre sıfırlama bağlantısı almak için e-posta adresinizi girin.</p>
      <input
        type="email"
        placeholder="E-posta"
        value={email}
        onChange={handleEmailChange}
      />
      <button onClick={handleForgotPassword}>Sıfırlama E-postası Gönder</button>
      <p>{message}</p>
    </div>
  );
};

export default ForgotPassword;
