import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import AdminHeader from "../components/Admin/AdminHeader";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { AuthContext } from "../context/authContext";
import { ToastContainer, toast } from "react-toastify";

const AdminEgitimAta = () => {
  const { currentUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [userpost, setUserpost] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/users/users`);
        setUsers(res.data);
      } catch (err) {
        toast.error("Userlar yüklenmedi");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedId(value);
  };
  const handleDeleteUser = async (egitimUserID) => {
    try {
      await axios.delete(`https://kidpedia.net/api/egitim/userdelete/${egitimUserID}`);
      handleSelect();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = async () => {
    try {
      const res = await axios.get(`https://kidpedia.net/api/egitim/userdata/${selectedId}`);
      setUserpost(res.data);
      console.log(res.data);
    } catch (err) {
      toast.error("Posta atanan kişiler yüklenmedi");
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedId) {
      handleSelect();
    }
  }, [selectedId]);

  if (currentUser !== null) {
    return (
      <>
        <AdminHeader />
        <Container>
          <ToastContainer />

          <Row>
            <Col md={6}>
              <select
                className="form-select"
                size="3"
                aria-label="size 3 select example"
                onChange={handleChange}
                style={{ height: "15rem", width: "100%" }}
              >
                {users.map((user) => (
                  <option key={user.ID} value={user.ID}>
                    {user.firstname} {user.lastname}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={6}>
              <ul className="user-list">
                {userpost.map((post) => (
                  <li>
                    {post.title}
                    <RiDeleteBin7Fill
                      className="pr-2"
                      onClick={() => handleDeleteUser(post.egitimuser_id)}
                      style={{
                        height: "1rem",
                        width: "1rem",
                        color: "blue",
                      }}
                    />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <h1>Bu sayfayı görmeye yetkiniz yok.</h1>
      </>
    );
  }
};

export default AdminEgitimAta;
