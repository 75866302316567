import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import AdminHeader from "../components/Admin/AdminHeader";

const EgitimWrite = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const state = useLocation().state;
  const [value, setValue] = useState(state?.desc || "");
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const[pdfFile,setPdfFile]=useState(null);
  const [img,setImg] = useState(state?.img)
  const[pdfControl,setPdfControl] = useState(state?.pdfurls || "")


  const navigate = useNavigate();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post("/upload", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    axios
      .get(`https://kidpedia.net/api/egitim/${id}`)
      .then((response) => {
        setTitle(response.data.title);
        setValue(response.data.desc);
        setFile(response.data.img);
        setCat(response.data.cat);
        setImg(response.data.img);
        setPdfControl(response.data.pdfurls);
        setPdfFile(response.data.pdfurls)

      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  console.log(pdfControl)


  const refreshPage = () => {
    navigate(0);
  };

  const uploadPdf = async () => {
    try {
      const formData = new FormData();
      formData.append("pdf", pdfFile); // Assuming pdfFile is the selected PDF file
      const res = await axios.post("https://kidpedia.net/api/upload-pdf", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const fileUrl = await upload();
    const pdfFileurl = await uploadPdf();

    try {
         await axios.put(`https://kidpedia.net/api/egitim/${id}`, {
          title,
          desc: value,
          cat,
          img: fileUrl?.length > 0 ? fileUrl : img,
          date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          ucad: currentUser.cat,
          uid: currentUser.ID,
          pdfUrls: pdfFileurl?.length > 0 ? pdfFileurl : pdfControl,

        })
       

      refreshPage();
    } catch (err) {
      console.log(err);
    }
  };

  // admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
    case 503422:
      return (
        <>
          <AdminHeader />
          <Container>
            <Form>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Başlık"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />

                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    className="h-75 "
                  />
                </Col>

                <Col sm={3}>
                  <div className="menu">
                    <div className="item">
                      <h4>Yayınla</h4>

                      <Form.Group
                        controlId="formFile"
                        className="mb-2"
                        id="file"
                        name=""
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          Resim Yükle
                        </Form.Label>
                        <Form.Control type="file" />
                      </Form.Group>
                      <Form.Group
                        controlId="formPdfFile"
                        className="mb-2"
                        id="pdf-file"
                        name=""
                        onChange={(e) => setPdfFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          PDF Dosyası Yükle
                        </Form.Label>
                        <Form.Control type="file" accept=".pdf" />
                      </Form.Group>
                      <div className="buttons">
                        <Button variant="success" onClick={handleClick}>
                          Yayınla
                        </Button>
                      </div>
                    </div>
                    <div className="item">
                      <h4>Kategoriler</h4>
                      <div className="cat">
                        {[
                         "erkencocukluk",
                         "ergenlikdonemi",
                         "aileegitimi",
                         "gelisimselaksamalar",
                         "davranisproblemleri",
                         "ogrenmesorunlari",
                        ].map((category) => (
                          <div key={category}>
                            <input
                              type="radio"
                              name="cat"
                              value={category}
                              id={category}
                              onChange={(e) => setCat(e.target.value)}
                              checked={cat === category}
                            />
                            <label htmlFor={category}>{category}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {`Yüklenmiş dosya: ${pdfFile}`}
              </Row>
            </Form>
          </Container>
        </>
      );
      break;

    case 503439:
      return <div>BackendUser</div>;
      break;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default EgitimWrite;
